import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import giantImage from '../../assets/vedi_giant2.png';
import galleryImage1 from '../../assets/wapp1.jpeg';
import galleryImage2 from '../../assets/wapp2.jpeg';
import galleryImage3 from '../../assets/wapp3.jpeg';

const Home = () => {
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    if (showContent) {
      const animatedTextElement = document.querySelector('.animated-text');
      if (animatedTextElement) {
        animatedTextElement.classList.add('fade-in');
      }

      const imageElement = document.querySelector('.giant-image');
      if (imageElement) {
        imageElement.classList.add('fade-in');
      }

      const rightSide = document.querySelector('.right-side');
      if (rightSide) {
        rightSide.classList.add('fade-in');
      }

      const menuLinkContainer = document.querySelector('.menu-link-container');
      if (menuLinkContainer) {
        menuLinkContainer.classList.add('fade-in');
      }
    }
  }, [showContent]);

  useEffect(() => {
    const workTimeElement = document.querySelector('.work-time');
    if (workTimeElement) {
      workTimeElement.classList.add('fade-in');
    }
    const continueButton = document.querySelector('.continue-button');
    if (continueButton) {
      continueButton.classList.add('fade-in');
    }

    const $cards = document.querySelectorAll('.card');
    $cards.forEach(card => {
      card.addEventListener('click', () => {
        $cards.forEach(c => c.style.zIndex = 3); // Reset z-index of all cards
        card.style.zIndex = 6; // Set z-index of clicked card to be the highest
      });
    });

    let $current = document.querySelector('.card--current');

    if (!$current && $cards.length > 0) {
      $current = $cards[$cards.length - 1];
      $cards[0]?.click();
    }

    document.querySelector('.cards')?.classList.add('cards--active');
  }, []);

  const handleContinueClick = () => {
    setShowContent(true);
  };

  return (
    <div className="home-container">
      {/* <div className='circle-badge'> 
        <link href="https://awards.infcdn.net/2024/badge-circledLeaves27.css" rel="stylesheet"/><a id="b-circledLeaves27" target="blank" href="https://restaurantguru.com/Bistro-Vedi-Bjelovar" class="b-circledLeaves27--light b-circledLeaves27--2025"> <span class="b-circledLeaves27title">Recommended</span> <span class="b-circledLeaves27separator"></span> <span class="b-circledLeaves27_name">Bistro Vedi</span></a>  
      </div> */}
      
      {!showContent && (
        <div className="intro-container">
          <div className="work-time">
            <p>
              <span className="first-letter-two"> Dobrodošli u Vedi Bistro </span>
              <br />
              <span className="first-letter">Radno vrijeme kuhinje:</span>
              <br />
              <span className="first-letter">10:00 - 22:00</span>
            </p>
          </div>
          <button className="continue-button" onClick={handleContinueClick}>Nastavi</button>
        </div>
      )}
      <img src={giantImage} alt="Giant" className="giant-image" />
      <div className="home-top-info">
  <div className="circle-badge"> 
    <link href="https://awards.infcdn.net/2024/badge-circledLeaves27.css" rel="stylesheet"/>
    <a id="b-circledLeaves27" target="_blank" href="https://restaurantguru.com/Bistro-Vedi-Bjelovar" className="b-circledLeaves27--light b-circledLeaves27--2025">
      <span className="b-circledLeaves27title">Recommended</span>
      <span className="b-circledLeaves27separator"></span>
      <span className="b-circledLeaves27_name">Bistro Vedi</span>
    </a>  
  </div>

  <div className="work-time lifted">
    <p>
      <span className='first-letter'>Rezervacije: <a className="phone-link-2" href="tel:+38543525190">043 525 190</a></span><br />
      <span className="first-letter">Radno vrijeme kuhinje:</span>
      <br />
      <span className="first-letter">10:00 - 22:00</span>
    </p>
  </div>
</div>
      {/* <div className="work-time">
        <p>
          <span className='first-letter'>Rezervacije: <a className="phone-link-2" href="tel:+38543525190">043 525 190</a></span><br />
          <span className="first-letter">Radno vrijeme kuhinje:</span>
          <br />
          
          <span className="first-letter">10:00 - 22:00</span>
        </p>
      </div> */}
      {showContent && (
        <div id="home" className="show">
          <div className="left-side">
            <div className="text-container">
              <p className="animated-text">
                <span className="chunk">
                  "<span className="first-letter">O</span>d zalogaja posutih prašinom starih hrastova do jela ispunjenih snagom drevnih divova..."
                </span>
                <br />
                <span className="chunk">
                  "<span className="first-letter">V</span>edi je mjesto gdje se svaka delicija pretvara u gastronomsku avanturu."
                </span>
                <br />
                <span className="chunk">
                  "<span className="first-letter">D</span>ođite i istražite okuse Bilogore u bistrou Vedi - mjestu gdje se mitovi pretvaraju u stvarnost za vaše nepce."
                </span>
              </p>
            </div>
          </div>
          <div className="right-side">
            <ul className="cards">
              <li className="card card--next">
                <img src={galleryImage1} alt="Gallery 1" className="gallery-image" />
              </li>
              <li className="card card--out">
                <img src={galleryImage2} alt="Gallery 2" className="gallery-image" />
              </li>
              <li className="card card--current">
                <img src={galleryImage3} alt="Gallery 3" className="gallery-image" />
              </li>
            </ul>
          </div>
          <div className="menu-link-container fade-in">
            <Link to="/menu" className="menu-link-text chunk">Pogledajte naš menu</Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
